<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="用工单位" prop="company_name">
          <el-input clearable v-model.trim="table.params.company_name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="员工姓名" prop="name">
          <el-input clearable v-model.trim="table.params.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input clearable v-model.trim="table.params.phone" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询
          </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:type="{row}">
        <span>{{row.type == 1 ? '完善基本信息' : '签署合同'}}</span>
      </template>
    </VTable>
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import {getStorage} from '@/storage'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: '',
  mixins:[mixinTable],
  components: {
    VTable,
  },
  data() {
    return {
      icId: Number(getStorage('icId')), // 登录账号的身份id（0：总公司）
      token: getStorage('token'),
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "id", label: "ID", width:"60"},
        { name: "name", label: "姓名", hidden: false },
        { name: "phone", label: "联系电话", hidden: false},
        { name: "company_name", label: "用工单位", hidden: false},
        { name: "type", label: "变动内容", showTooltip:true, hidden: false},
        { name: "created_at", label: "通知时间",  hidden: false},
      ],
      
      table: {
        loading: false,
        params: {
          name: '',
          phone: '',
          company_name: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },

    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/employee/changeList', {params:this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  }
}
</script>